import React, {FC, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {navigate} from 'gatsby';

import Button from '../../components/button';
import Section from '../../components/section';
import Layout from '../../components/layout/layout';
import RequestDemoCta from '../../components/request-demo-cta';

import videoBannerLg from '../../images/why-hexomatic/video-banner/why-hexomatic-lg.png';
import videoBannerMd from '../../images/why-hexomatic/video-banner/why-hexomatic-md.png';
import videoBannerSm from '../../images/why-hexomatic/video-banner/why-hexomatic-sm.png';

import teams from '../../images/why-hexomatic/customers/teams.png';
import individuals from '../../images/why-hexomatic/customers/individuals.png';
import largeEnterprises from '../../images/why-hexomatic/customers/larger-enterprises.png';

import workflowCategoriesLg from '../../images/why-hexomatic/workflow-categories/workflow-categories-lg.png';
import workflowCategoriesSm from '../../images/why-hexomatic/workflow-categories/workflow-categories-sm.png';

import section2 from '../../images/why-hexomatic/sections/automate.png';
import section3 from '../../images/why-hexomatic/sections/run-workflow.png';
import section1 from '../../images/why-hexomatic/sections/scrape-any-website.png';

import './styles.scss';

const articleSection = [
  {
    img: individuals,
    subtitle: 'INDIVIDUALS',
  },
  {
    img: teams,
    subtitle: 'TEAMS',
  },
  {
    img: largeEnterprises,
    subtitle: 'LARGER ENTERPRISES',
  },
];

const WhyHexomatic: FC = () => {
  const [iframe, setIframe] = useState(false);
  const [iframeMobile, setIframeMobile] = useState(false);

  return (
    <Layout>
      <div className="why-hexomatic pricing_promo_page">
        <Section sectionImage lightBlue className="upper-section" top>
          <Col className="title-section" lg={6}>
            <h1 className="title">
              Why we
              <p className="primary-color">built Hexomatic</p>
            </h1>
            <div className="d-lg-none d-flex justify-content-center">
              {iframe ? (
                <iframe
                  src="https://player.vimeo.com/video/738746573?h=b08c2e2b95&loop=1&autoplay=1"
                  width="640"
                  height="338"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  style={{maxWidth: '100%', margin: 'auto'}}
                />
              ) : (
                <img
                  src={videoBannerLg}
                  srcSet={`${videoBannerSm} 257w, ${videoBannerMd} 386w, ${videoBannerLg} 513w`}
                  sizes="(max-width: 585px) 257px,(max-width: 1200px) 386px, (min-width: 1200.98px) 513px"
                  alt="Why Hexomatic"
                  onClick={() => setIframe(true)}
                  className="cursor-pointer section-image-iframe"
                />
              )}
            </div>
            <div className="title-pagehero">
              Web scraping is the secret sauce that empowers the biggest companies in the world to tap into the internet
              as their own data source.
            </div>
          </Col>
          <Col className="d-lg-flex d-none justify-content-end" lg={6}>
            <div>
              {iframeMobile ? (
                <iframe
                  src="https://player.vimeo.com/video/738746573?h=b08c2e2b95&loop=1&autoplay=1"
                  width="545"
                  height="282"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  style={{maxWidth: '100%', margin: 'auto'}}
                ></iframe>
              ) : (
                <img
                  src={videoBannerLg}
                  srcSet={`${videoBannerSm} 257w, ${videoBannerMd} 386w, ${videoBannerLg} 513w`}
                  sizes="(max-width: 585px) 257px,(max-width: 1200px) 386px, (min-width: 1200.98px) 513px"
                  alt="Why Hexomatic"
                  onClick={() => setIframeMobile(true)}
                  className="cursor-pointer section-image-iframe"
                />
              )}
            </div>
          </Col>
        </Section>

        <Section className="pt-5 pb-4">
          <h2 className="title text-center">
            There has to be <span className="primary-color">a simpler way</span> <br /> to automate tasks, right?
          </h2>
          <p className="section-article-text p-2 m-auto d-flex d-md-none">
            If you have ever tried web scraping or automating tasks you know how painful and expensive this can be...So
            unless you’re a developer or an enterprise with a sizeable budget, you’re missing out on the next industrial
            revolution.
          </p>
          <Row className="justify-content-center m-auto">
            {articleSection.map(item => (
              <Col className="why-section" lg={4}>
                <img src={item.img} alt={item.subtitle} width={350} height={219} className="image-styles" />
                <p className="subtitle">{item.subtitle}</p>
              </Col>
            ))}
            <p className="section-article-text p-2 m-auto d-none d-md-flex">
              If you have ever tried web scraping or automating tasks you know how painful and expensive this can
              be...So unless you’re a developer or an enterprise with a sizeable budget, you’re missing out on the next
              industrial revolution.
            </p>
          </Row>
        </Section>

        <Section dark sectionDots>
          <div className="mt-4">
            <h2 className="section-title">
              Introducing a better way to
              <span className="primary-color">
                &nbsp;capture <br /> data&nbsp;
              </span>
              from the web
            </h2>
            <div className="section-article">
              <p className="capture-data-text">
                Hexomatic makes web scraping easy and goes beyond just providing data… With over 100+ automations, you
                can perform entire sales, marketing, and research tasks on autopilot.
              </p>
            </div>
            <div className="introduction-section d-flex d-lg-none justify-content-center">
              <img
                src={workflowCategoriesSm}
                className="mt-3 image-styles"
                alt="Sales, marketing, and reseach tasks"
                width={432}
                height={529}
                style={{maxWidth: '432px'}}
              />
            </div>
            <Col className="d-lg-flex d-none justify-content-center" lg={12}>
              <img
                src={workflowCategoriesLg}
                className="my-0 mt-3 image-styles"
                alt="Sales, marketing, and reseach tasks"
                width={900}
                height={380}
                style={{maxWidth: '900px'}}
              />
            </Col>
            <div className="section-button">
              <Button primary className="btn" onClick={() => navigate('/pricing/')}>
                Get started in minutes
              </Button>
            </div>
          </div>
        </Section>

        <Section className="py-40">
          <Col lg={6} className="align-self-center">
            <div className="order-number d-none d-lg-block">
              <span>1</span>
            </div>
            <h2 className="title">Scrape any website</h2>
            <div className="d-flex">
              <img
                src={section1}
                className="d-lg-none image-styles"
                alt="Scrape any website"
                width={400}
                height={272}
                style={{maxWidth: '400px', margin: 'auto'}}
              />
            </div>
            <p className="title-pagehero">
              Scrape any website using a simple point-and-click interface and tap into a growing library of pre-built
              recipes for the most popular websites.
            </p>
          </Col>
          <Col lg={6} className="d-none d-lg-flex">
            <img
              src={section1}
              className="image-styles"
              alt="Scrape any website"
              width={515}
              height={350}
              style={{maxWidth: '515px', margin: 'auto'}}
            />
          </Col>
        </Section>

        <Section>
          <Col lg={6} className="d-none d-lg-flex">
            <img
              src={section2}
              className="image-styles"
              alt="Automate tasks"
              width={515}
              height={350}
              style={{maxWidth: '515px', margin: 'auto'}}
            />
          </Col>
          <Col lg={6} className="align-self-center">
            <div className="order-number d-none d-lg-block">
              <span>2</span>
            </div>
            <h2 className="title">Automate common sales, marketing & research tasks</h2>
            <div className="d-flex">
              <img
                src={section2}
                className="d-lg-none image-styles"
                alt="Automate tasks"
                width={400}
                height={272}
                style={{maxWidth: '400px', margin: 'auto'}}
              />
            </div>
            <div>
              <div>
                <p className="title-pagehero">
                  Tap into 100+ ready-made automations to perform work tasks on your data, including capturing contact
                  details, validating email addresses, or extracting media files in minutes.
                </p>
              </div>
            </div>
          </Col>
        </Section>

        <Section className="py-40">
          <Col lg={6} className="align-self-center">
            <div className="order-number d-none d-lg-block">
              <span>3</span>
            </div>
            <h2 className="title">Run entire end-to-end workflows on autopilot</h2>
            <div className="d-flex">
              <img
                src={section3}
                className="d-lg-none image-styles"
                alt="Run workflow"
                width={400}
                height={272}
                style={{maxWidth: '400px', margin: 'auto'}}
              />
            </div>
            <p className="title-pagehero">
              Go beyond data capture, by combining web scraping and ready-made automations to perform common sales,
              marketing, and research tasks like magic.
            </p>
            <div className="d-flex d-lg-block">
              <Button className="btn m-auto" onClick={() => navigate('/pricing/')}>
                Get started in minutes
              </Button>
            </div>
          </Col>
          <Col lg={6} className="d-none d-lg-flex">
            <img
              src={section3}
              className="image-styles"
              alt="Run workflow"
              width={515}
              height={350}
              style={{maxWidth: '515px', margin: 'auto'}}
            />
          </Col>
        </Section>

        <Section lightBlue className="py-40 px-3">
          <RequestDemoCta />
        </Section>
      </div>
    </Layout>
  );
};

export default WhyHexomatic;
